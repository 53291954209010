import type { Notification } from '#ui/types'

const getConfig = (titleOrConfig: string | Partial<Notification>) => {
  return typeof titleOrConfig === 'string' ? { title: titleOrConfig } : titleOrConfig
}

export const useToastAlert = () => {
  const toast = useToast()
  return {
    add: toast.add,
    // refer to optionsShared.alert:
    info: (titleOrConfig: string | Partial<Notification>) => toast.add({
      color: 'blue',
      icon: 'i-mdi-information',
      ...getConfig(titleOrConfig),
    }),
    warning: (titleOrConfig: string | Partial<Notification>) => toast.add({
      color: 'amber',
      icon: 'i-mdi-alert',
      ...getConfig(titleOrConfig),
    }),
    error: (titleOrConfig: string | Partial<Notification>) => toast.add({
      color: 'red',
      icon: 'i-mdi-alert-circle',
      timeout: 7500,
      ...getConfig(titleOrConfig),
    }),
    success: (titleOrConfig: string | Partial<Notification>) => toast.add({
      color: 'green',
      icon: 'i-mdi-check-circle',
      ...getConfig(titleOrConfig),
    }),
  }
}
